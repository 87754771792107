<template>
  <div>
    <div class="row mb-2 pe-4">
      <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
        <div class="color-common">
          <h3 class="title-page mb-0 ps-3">TOP UP</h3>
        </div>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100 my-transacions">
      <span class="mask bg-white"></span>
      <div class="container">
        <div style="margin-top: 15px; margin-bottom: 50px">
          <div class="d-flex justify-content-between">
            <h3 class="title-page">Regular Top Up</h3>
            <material-button
              @click="addRegularTopUp()"
              :disabled="disableBtnSubmit"
              class="mx-2 btn-background btn-sm"
            >
              Add Regular Top Up
            </material-button>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12 mx-auto">
              <div class="row mt-4">
                <div class="col-12">
                  <div class="card-custom">
                    <div class="px-0 pb-2">
                      <div class="table-responsive p-0 custom-table">
                        <table class="table align-items-center mb-0">
                          <thead>
                            <tr>
                              <th class="text-center font-weight-bolder w-20">
                                Topup Name
                              </th>
                              <!-- <th class="text-center font-weight-bolder w-5">
                                Total to Add
                              </th> -->
                              <th class="text-center font-weight-bolder w-20">
                                {{ $t("Payment_Amount") }} $
                              </th>
                              <th class="text-center font-weight-bolder">
                                {{ $t("Paid_Amount_Expiry") }}
                              </th>
                              <th class="text-center font-weight-bolder w-20">
                                {{ $t("Bonus_Amount") }} $
                              </th>
                              <th class="text-center font-weight-bolder">
                                {{ $t("Bonus_Expiry") }}
                              </th>
                              <th class="text-center font-weight-bolder w-10">
                                {{ $t("action") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody v-if="topUps.length">
                            <tr v-for="(value, key) in topUps" :key="key">
                              <td class="text-center text-xss">
                                {{ value.topup_name }}
                              </td>
                              <!-- <td class="text-center text-xss">
                                {{ value.topup_amount_format }}
                              </td> -->
                              <td class="text-left text-xss">
                                {{ value.payment_amount_format }}
                              </td>
                              <td class="text-left text-xss">
                                {{ value.paid_amount_expiry_text }}
                              </td>
                              <td class="text-left text-xss">
                                {{ value.bonus_amount_format }}
                              </td>
                              <td class="text-left text-xss">
                                {{ value.bonus_expiry_text }}
                              </td>
                              <td class="align-middle text-center text-sm">
                                <a
                                  class="btn btn-link text-dark px-3 mb-0"
                                  @click="editRegularTopUp(value.id)"
                                >
                                  <i
                                    class="fas fa-pencil-alt text-dark me-2"
                                    aria-hidden="true"
                                  ></i
                                  >Edit
                                </a>
                                <a
                                  class="btn btn-link text-danger text-gradient px-3 mb-0"
                                  data-bs-toggle="modal"
                                  data-bs-target="#modalConfirm"
                                  href="javascript:;"
                                  @click="confirmDelete(value.id)"
                                >
                                  <i
                                    class="far fa-trash-alt me-2"
                                    aria-hidden="true"
                                  ></i
                                  >Remove
                                </a>
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td colspan="5" class="text-center">
                                No item available
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <pagination
                      v-if="pagination.last_page > 1"
                      :pagination="pagination"
                      :current_page="pagination.current_page"
                      :last_page="pagination.last_page"
                    />
                    <div class="text-center mt-2 d-flex justify-content-end">
                      <div class="dropdown">
                        <button
                          class="btn btn-secondary dropdown-toggle btn-sm"
                          type="button"
                          id="dropdownMenuButton1"
                          style="margin-top: 13px"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          aria-haspopup="true"
                        >
                          {{ $t("download") }}
                        </button>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <a
                              class="dropdown-item"
                              @click="exportFile('csv')"
                              href="#"
                              >Export CSV</a
                            >
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              @click="exportFile('xlsx')"
                              href="#"
                              >Export Excel</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 15px; margin-bottom: 50px">
          <div class="d-flex justify-content-between">
            <h3 class="title-page">Promotional Top Up</h3>
            <material-button
              @click="addPromotionalTopUp()"
              :disabled="disableBtnSubmit"
              class="mx-2 btn-background btn-sm"
            >
              Add Promotional Top Up
            </material-button>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12 mx-auto">
              <div class="row mt-4">
                <div class="col-12">
                  <div class="card-custom">
                    <div class="px-0 pb-2">
                      <div class="table-responsive p-0 custom-table">
                        <table class="table align-items-center mb-0">
                          <thead>
                            <tr>
                              <th class="text-center font-weight-bolder w-20">
                                Topup Name
                              </th>
                              <th class="text-center font-weight-bolder w-20">
                                {{ $t("Payment_Amount") }} $
                              </th>
                              <th class="text-center font-weight-bolder">
                                {{ $t("Paid_Amount_Expiry") }}
                              </th>
                              <th class="text-center font-weight-bolder w-20">
                                Promotion
                              </th>
                              <th class="text-center font-weight-bolder">
                                Image
                              </th>
                              <th class="text-center font-weight-bolder w-10">
                                {{ $t("action") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody v-if="promotionalTopUps.length">
                            <tr v-for="(value, key) in promotionalTopUps" :key="key">
                              <td class="text-center text-xss">
                                {{ value.name }}
                              </td>
                              <!-- <td class="text-center text-xss">
                                {{ value.topup_amount_format }}
                              </td> -->
                              <td class="text-left text-xss">
                                {{ value.payment_amount }}
                              </td>
                              <td class="text-left text-xss">
                                {{ value.expiry_text }}
                              </td>
                              <td class="text-left text-xss">
                                {{ value.promotion_type }}
                              </td>
                              <td class="text-left text-xss">
                                <img :src="value.image" width="70px" height="70px">
                              </td>
                              <td class="align-middle text-center text-sm">
                                <a
                                  class="btn btn-link text-dark px-3 mb-0"
                                  @click="editPromotionalTopUp(value.id)"
                                >
                                  <i
                                    class="fas fa-pencil-alt text-dark me-2"
                                    aria-hidden="true"
                                  ></i
                                  >Edit
                                </a>
                                <a
                                  class="btn btn-link text-danger text-gradient px-3 mb-0"
                                  data-bs-toggle="modal"
                                  data-bs-target="#modalConfirm"
                                  href="javascript:;"
                                  @click="confirmDelete(value.id)"
                                >
                                  <i
                                    class="far fa-trash-alt me-2"
                                    aria-hidden="true"
                                  ></i
                                  >Remove
                                </a>
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td colspan="5" class="text-center">
                                No item available
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 15px; margin-bottom: 50px">
          <div class="mt-5 row">
            <div
              class="col-lg-12 col-md-12 col-12 mx-auto"
              style="white-space: pre-wrap !important"
            >
              <div class="ps-0 label-form">Terms & Conditions</div>
              <ckeditor
                :editor="editor"
                v-model="terms_conditions"
                :config="editorConfig"
              ></ckeditor>
            </div>
          </div>
          <div class="text-center mt-4 d-flex justify-content-end">
            <material-button
              @click="saveTermsConditions()"
              class="btn-background btn-sm"
            >
              Save
            </material-button>
          </div>
        </div>
      </div>
      <ModalConfirm
        message="Are you sure you want to delete ?"
        :process-yes="processYes"
      />
      <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title=""
          date=""
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
      <material-loading :active="loaderActive" />
      <form
        :action="`${actionDownload}/merchant/download-topups`"
        method="POST"
      >
        <input type="hidden" name="merchantId" :value="merchantIdExport" />
        <input type="hidden" name="merchant_code" :value="merchantCodeExport" />
        <input type="hidden" name="token" :value="token" />
        <input type="hidden" name="action_export" :value="action_export" />
        <input type="hidden" name="action" value="export" />
        <input id="downloadForm" type="submit" class="d-none" />
      </form>
      <ModalAddRegularTopUp
        :keyReload="keyReloadAddRegularTopUp"
        :isEdit="isEditRegularTopUp"
        @reload-data="reloadRegularTopUp"
      ></ModalAddRegularTopUp>
      <ModalAddPromotionalTopUp
        :keyReload="keyReloadAddPromotionalTopUp"
        :isEdit="isEditPromotionalTopUp"
        @reload-data="reloadPromotionalTopUp"
      ></ModalAddPromotionalTopUp>
    </div>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import Pagination from "@/components/Pagination.vue";
import "bootstrap/js/dist/modal";
import MaterialButton from "@/components/MaterialButton.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import TopUpService from "../../../services/settingTopUp.service";
import ModalConfirm from "@/components/ModalConfirm.vue";
import "@vueform/multiselect/themes/default.css";
import $ from "jquery";
import TokenService from "../../../services/token.service";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ModalAddRegularTopUp from "../topup/components/ModalAddRegularTopUp.vue";
import ModalAddPromotionalTopUp from "../topup/components/ModalAddPromotionalTopUp.vue";

export default {
  name: "Topup",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    ModalConfirm,
    Pagination,
    ModalAddRegularTopUp,
    ModalAddPromotionalTopUp
  },
  data() {
    return {
      nameIsRequired: false,
      message: "",
      snackbar: null,
      loaderActive: false,
      topUps: [],
      promotionalTopUps: [],
      pagination: {},
      page: 1,
      topUpId: null,
      isLoadData: true,
      labelGroup: "ADD NEW TOP UP AMOUNT",
      terms_conditions: "",
      action_export: "csv",
      actionDownload: process.env.VUE_APP_BASE_URL,
      token: TokenService.getLocalAccessToken(),
      merchantIdExport: localStorage.getItem("MERCHANT_ID", ""),
      merchantCodeExport: localStorage.getItem("MERCHANT_CODE", ""),
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "bold",
            "italic",
            "|",
            "undo",
            "redo",
          ],
        },
      },
      isEditRegularTopUp: 0,
      isEditPromotionalTopUp: 0,
      keyReloadAddRegularTopUp: "keyReloadAddRegularTopUp",
      keyReloadAddPromotionalTopUp: "keyReloadAddPromotionalTopUp",
      promotionalTopup: 2,
    };
  },
  mounted() {
    this.getList(this.page);
    this.getListPromotionalTopup();
  },
  methods: {
    exportFile(type = "csv") {
      this.action_export = type;
      setTimeout(() => {
        $("#downloadForm").click();
      }, 150);
    },
    getList(page) {
      this.topUps = [];
      this.showLoader();
      var dataForm = {
        page: page,
      };
      TopUpService.getList(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.topUps = response.data.data.topupList.items;
            this.pagination = response.data.data.topupList;
            this.page = this.pagination.current_page;
            this.terms_conditions = response.data.data.termsConditions ?? "";
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    getListPromotionalTopup() {
      this.promotionalTopUps = [];
      this.showLoader();
      var dataForm = {
        type: this.promotionalTopup,
      };

      TopUpService.getList(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.promotionalTopUps = response.data.data.topupList;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    saveTermsConditions() {
      this.showLoader();
      let dataForm = {
        terms_conditions: this.terms_conditions,
      };

      TopUpService.saveTermsConditions(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.snackbar = "success";
            this.message = "Update Terms & Conditions Success";
            this.setTimeOutNotification();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    reloadRegularTopUp(mess) {
      this.snackbar = "success";
      this.message = mess;
      this.setTimeOutNotification();
      this.page = 1;
      this.getList(this.page);
    },
    reloadPromotionalTopUp(mess) {
      this.snackbar = "success";
      this.message = mess;
      this.setTimeOutNotification();
      this.getListPromotionalTopup();
    },
    addRegularTopUp() {
      this.keyReloadAddRegularTopUp = Math.random();
      this.isEditRegularTopUp = 0;
      $("#show-modal-add-regular-topup").click();
    },
    editRegularTopUp(topUpId) {
      this.keyReloadAddRegularTopUp = Math.random();
      this.isEditRegularTopUp = topUpId;
      $("#show-modal-add-regular-topup").click();
    },
    addPromotionalTopUp() {
      this.keyReloadAddPromotionalTopUp = Math.random();
      this.isEditPromotionalTopUp = 0;
      $("#show-modal-add-promotional-topup").click();
    },
    editPromotionalTopUp(topUpId) {
      this.keyReloadAddPromotionalTopUp = Math.random();
      this.isEditPromotionalTopUp = topUpId;
      $("#show-modal-add-promotional-topup").click();
    },
    processYes() {
      $(".btn-close-modal").click();
      this.showLoader();
      TopUpService.delete(this.topUpId).then(
        (response) => {
          if (response.data.result.isSuccess) {
            if (this.topUps.length == 1) {
              this.page = this.page - 1;
            }
            this.getList(this.page);
            this.snackbar = "success";
            this.message = "Delete top up success";
            this.setTimeOutNotification();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    confirmDelete(topUpId) {
      this.topUpId = topUpId;
      $(".btn-confirm-modal").click();
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    closeSnackbar() {
      this.snackbar = null;
    },
    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
};
</script>
<style scoped>
.multiselect {
  min-width: 135px;
}

.r-no-expiry .form-check {
  padding-left: 0 !important;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 50%;
    margin: 1.75rem auto;
  }
}
</style>
