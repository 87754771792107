<template>
  <div>
    <button
      style="display: none"
      type="button"
      id="show-modal-add-promotional-topup"
      data-bs-toggle="modal"
      data-bs-target="#ModalAddPromotionalTopUp"
    ></button>
    <div
      class="modal fade"
      id="ModalAddPromotionalTopUp"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{
                isEdit
                  ? "EDIT PROMOTIONAL TOP UP"
                  : "ADD NEW PROMOTIONAL TOP UP"
              }}
            </h5>
          </div>
          <div class="modal-body">
            <div class="row d-flex bd-highlight mt-3">
              <div class="col-lg-4 col-4 align-self-center">
                Topup Name<span class="text-danger ms-2">*</span>
              </div>
              <div
                class="col-lg-8 col-8 d-flex bd-highlight d-flex justify-content-end"
              >
                <material-input
                  v-model="topup.name"
                  extraClass="ml-5"
                  placeholder="Enter topup name"
                />
              </div>
            </div>
            <div
              class="row d-flex bd-highlight"
              v-if="fieldRequired.isTopupNameRequire"
            >
              <div class="col-lg-4 col-4 align-self-center"></div>
              <div class="col-lg-8 col-8 d-flex bd-highlight">
                <span class="is-invalid">{{
                  textRequireTopupAmountCustom
                }}</span>
              </div>
            </div>
            <div class="row d-flex bd-highlight mt-3">
              <div class="col-lg-4 col-4 align-self-center">
                Payment Amount<span class="text-danger ms-2">*</span>
              </div>
              <div
                class="col-lg-8 col-8 d-flex bd-highlight d-flex align-items-center"
              >
                <material-input
                  v-model="topup.payment_amount"
                  extraClass="ml-5 me-2"
                  placeholder="Enter number of times"
                  type="number"
                />
                <div class="w-75">Times from Bill Amount</div>
              </div>
            </div>
            <div
              class="row d-flex bd-highlight"
              v-if="fieldRequired.isPaymentAmountRequire"
            >
              <div class="col-lg-4 col-4 align-self-center"></div>
              <div class="col-lg-8 col-8 d-flex bd-highlight">
                <span class="is-invalid">{{
                  textRequirePaymentAmountCustom
                }}</span>
              </div>
            </div>
            <div class="row d-flex bd-highlight mt-3">
              <div class="col-lg-4 col-4 align-self-center">
                Paid Amount Expiry<span class="text-danger ms-2">*</span>
              </div>
              <div
                class="col-lg-3 col-3 d-flex bd-highlight d-flex justify-content-end pe-5"
              >
                <material-input
                  v-model="topup.expiry_number"
                  extraClass="ml-5"
                  placeholder="Number"
                  type="number"
                />
              </div>
              <div
                class="col-lg-3 col-3 d-flex bd-highlight d-flex justify-content-end"
              >
                <Multiselect
                  placeholder="Select Time"
                  :canClear="false"
                  :options="timeUnits"
                  v-model="topup.expiry_unit"
                />
              </div>
              <div
                class="col-lg-2 col-2 d-flex bd-highlight d-flex justify-content-center align-items-center r-no-expiry"
              >
                <material-checkbox
                  v-model="topup.noExpiry"
                  :checked="topup.noExpiry"
                  name="no_expiry"
                  id="no_expiry"
                  class="checkbox-custom"
                >
                  <template v-slot:label>No Expiry</template></material-checkbox
                >
              </div>
            </div>
            <div
              class="row d-flex bd-highlight"
              v-if="fieldRequired.isExpiryRequire"
            >
              <div class="col-lg-4 col-4 align-self-center"></div>
              <div class="col-lg-8 col-8 d-flex bd-highlight">
                <span class="is-invalid">{{ textRequireExpiryCustom }}</span>
              </div>
            </div>
            <div class="row d-flex bd-highlight mt-3">
              <div class="col-lg-4 col-4 align-self-center">
                Promotion<span class="text-danger ms-2">*</span>
              </div>
              <div class="col-lg-8 col-8">
                <MaterialRadio
                  v-model="topup.promotion_type"
                  id="free_biill"
                  value="1"
                  class="align-items-center ps-0"
                >
                  Free the current bill
                </MaterialRadio>
                <!-- <MaterialRadio
                  v-model="topup.promotion_type"
                  id="get_voucher"
                  value="2"
                  class="align-items-center ps-0"
                >
                  Get a voucher
                </MaterialRadio> -->
              </div>
            </div>
            <div class="row d-flex bd-highlight mt-3">
              <div class="col-lg-4 col-4 align-self-center">
                Limit of use<span class="text-danger ms-2">*</span>
              </div>
              <div class="col-lg-8 col-8">
                <MaterialRadio
                  v-model="topup.limit_type"
                  id="free_biill"
                  value="1"
                  class="align-items-center ps-0"
                >
                  One time per member
                </MaterialRadio>
                <MaterialRadio
                  v-model="topup.limit_type"
                  id="get_voucher"
                  value="2"
                  class="align-items-center ps-0"
                >
                  Multiple times per member
                </MaterialRadio>
              </div>
            </div>
            <div class="row d-flex bd-highlight mt-3" v-if="topup.limit_type == 2">
              <div class="col-lg-4 col-4 align-self-center"></div>
              <div
                class="col-lg-8 col-8 d-flex bd-highlight d-flex align-items-center"
              >
                <material-input
                  v-model="topup.limit_number"
                  extraClass="ml-5 me-2"
                  placeholder="Enter number of times"
                  type="number"
                />
                <div class="w-75 me-2">Times for every</div>
                <Multiselect
                  placeholder="Select Time"
                  :canClear="false"
                  :options="limitUnits"
                  v-model="topup.limit_unit"
                />
              </div>
            </div>
            <div
              class="row d-flex bd-highlight"
              v-if="fieldRequired.isLimitRequire"
            >
              <div class="col-lg-4 col-4 align-self-center"></div>
              <div class="col-lg-8 col-8 d-flex bd-highlight">
                <span class="is-invalid">{{ textRequireLimitCustom }}</span>
              </div>
            </div>
            <div class="row d-flex bd-highlight mt-3">
              <div class="col-lg-4 col-4 align-self-center">Image</div>
              <div
                class="col-lg-8 col-8 d-flex align-items-center"
                style="color: #50565d !important; font-size: 14px"
              >
                <div class="d-flex justify-content-left align-items-center">
                  <div class="align-items-center">
                    <material-input
                      style="display: none"
                      type="file"
                      id="image"
                      @change="handleFileUpload($event)"
                      accept="image/*"
                    />
                    <material-button
                      @click="uploadFile()"
                      :disabled="false"
                      class="btn-background btn btn-sm capitalize"
                    >
                      Upload Image
                    </material-button>
                    <div
                      class="is-invalid d-flex align-items-center pe-2"
                      v-if="fieldRequired.isImageRequire"
                    >
                      {{ messageImageError }}
                    </div>
                    <div class="text-size-13">
                      Uploaded image minimum 100 x 100.
                    </div>
                  </div>
                  <div id="preview" class="ps-2" style="position: relative">
                    <img
                      v-if="urlImagePreview || topup.image"
                      :src="urlImagePreview ? urlImagePreview : topup.image"
                    />
                    <i
                      class="fa fa-times icon-close-sidenav-main danger"
                      v-if="urlImagePreview || topup.image"
                      style="
                        position: absolute;
                        top: -8px;
                        left: 130px;
                        color: #f44335;
                        font-size: 20px;
                      "
                      aria-hidden="true"
                      @click="clearImage()"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal content-->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-success btn-background btn-sm"
              @click="saveTopup"
            >
              Save
            </button>
            <button
              type="button"
              class="btn btn-primary btn-close-modal-add-promotional-topup btn-sm"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
        <div class="position-fixed top-1 end-1 z-index-2 message-success">
          <material-snackbar
            v-if="snackbar === 'danger' || snackbar === 'success'"
            title
            date
            :description="message"
            :icon="{ component: 'campaign', color: 'white' }"
            :color="snackbar"
            :close-handler="closeSnackbar"
          />
        </div>
        <material-loading :active="loaderActive" />
      </div>
    </div>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import Multiselect from "@vueform/multiselect";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import TopUpService from "../../../../services/settingTopUp.service";
import $ from "jquery";
import MaterialRadio from "@/components/MaterialRadio.vue";
import MaterialButton from "@/components/MaterialButton.vue";

export default {
  name: "ModalAddPromotionalTopUp",
  components: {
    MaterialSnackbar,
    MaterialInput,
    Multiselect,
    MaterialLoading,
    MaterialCheckbox,
    MaterialRadio,
    MaterialButton,
  },
  props: {
    keyReload: {
      type: String,
      required: true,
    },
    isEdit: {
      type: Number,
      required: 0,
    },
  },
  data() {
    return {
      loaderActive: false,
      message: "",
      snackbar: null,
      urlImagePreview: null,
      image: null,
      messageImageError: "",
      isErrorUploadImage: false,
      isImageTooSmall: false,
      topup: {
        id: 0,
        name: "",
        image: null,
        payment_amount: 0,
        expiry_number: 0,
        expiry_unit: 1,
        promotion_type: 1,
        limit_type: 1,
        limit_number: 0,
        limit_unit: 1,
        noExpiry: false,
        type: 2 // Promotional Top Up
      },
      timeUnits: [
        {
          value: 1,
          label: "days",
        },
        {
          value: 2,
          label: "months",
        },
        {
          value: 3,
          label: "years",
        }
      ],
      limitUnits: [
        {
          value: 1,
          label: "1 day",
        },
        {
          value: 2,
          label: "1 week",
        },
        {
          value: 3,
          label: "1 month",
        },
        {
          value: 4,
          label: "3 month",
        },
        {
          value: 5,
          label: "1 year",
        }
      ],
      fieldRequired: {},
      textRequireTopupAmountCustom: "",
      textRequirePaymentAmountCustom: "",
      textRequireExpiryCustom: "",
      textRequireLimitCustom: "",
    };
  },
  methods: {
    uploadFile() {
      $("#image").click();
    },
    handleFileUpload(event) {
      this.isImageTooSmall = false;
      let file = event.target.files[0];
      if (file != null || file != undefined) {
        this.topup.image = null;
        this.urlImagePreview = null;
        if (
          !["image/png", "image/jpg", "image/jpeg", "image/svg"].includes(
            file.type
          )
        ) {
          this.fieldRequired.isImageRequire = true;
          this.isErrorUploadImage = false;
          this.messageImageError = `File must be an image.`;
        } else {
          this.topup.image = file;
          this.urlImagePreview = URL.createObjectURL(this.topup.image);
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (evt) => {
            let img = new Image();
            img.onload = () => {
              let width = img.width;
              let height = img.height;
              if (width < 100 || height < 100) {
                this.messageImageError = `The image width ${width} x ${height} is too small.`;
                this.fieldRequired.isImageRequire = true;
                this.isErrorUploadImage = true;
                this.isImageTooSmall = true;
              } else {
                this.fieldRequired.isImageRequire = false;
                this.isErrorUploadImage = false;
                this.isImageTooSmall = false;
              }
            };
            img.src = evt.target.result;
          };
        }
      } else {
        this.topup.image = null;
        this.urlImagePreview = null;
        this.fieldRequired.isImageRequire = true;
        this.isErrorUploadImage = false;
        this.messageImageError = `File must be an image.`;
      }
    },

    clearImage() {
      this.topup.image = null;
      this.urlImagePreview = null;
      this.fieldRequired.isImageRequire = false;
      this.isErrorUploadImage = false;
      this.isImageTooSmall = false;
    },
    topupDetail() {
      this.showLoader();
      TopUpService.detail(this.isEdit, this.topup.type).then(
        (response) => {
          if (response.data.result.isSuccess) {
            const topup = response.data.data;
            this.isLoadDoneData = true;
            this.topup.id = topup.id ?? 0;
            this.topup.name = topup.name ?? "";
            this.topup.image = topup.image ?? null;
            this.topup.payment_amount = topup.payment_amount ?? 0;
            this.topup.expiry_number = topup.expiry_number ?? 0;
            this.topup.expiry_unit = topup.expiry_unit ?? 1;
            this.topup.promotion_type = topup.promotion_type ?? 1;
            this.topup.limit_type = topup.limit_type ?? 1;
            this.topup.limit_number = topup.limit_number ?? 0;
            this.topup.limit_unit = topup.limit_unit ?? 1;
            this.topup.noExpiry = topup.expiry_number ? false : true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    saveTopup() {
      if (this.processValidate()) {
        if (!this.topup.id) {
          TopUpService.store(this.topup).then(
            (response) => {
              if (response.data.result.isSuccess) {
                $(".btn-close-modal-add-promotional-topup").click();
                this.$emit("reload-data", "Add Promotional Top Up Success");
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        } else {
          TopUpService.update(this.topup).then(
            (response) => {
              if (response.data.result.isSuccess) {
                $(".btn-close-modal-add-promotional-topup").click();
                this.$emit("reload-data", "Update Promotional Top Up Success");
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        }
      }
    },
    resetRequired() {
      this.fieldRequired.isTopupNameRequire = false;
      this.fieldRequired.isPaymentAmountRequire = false;
      this.fieldRequired.isExpiryRequire = false;
      this.fieldRequired.isLimitRequire = false;
    },
    processValidate() {
      this.resetRequired();
      var isValid = true;
      if (this.topup.name == "" || this.topup.name == null) {
        this.fieldRequired.isTopupNameRequire = true;
        this.textRequireTopupAmountCustom = "The field is required.";
        isValid = false;
      }
      if (
        this.topup.payment_amount == "" ||
        this.topup.payment_amount == null
      ) {
        this.fieldRequired.isPaymentAmountRequire = true;
        this.textRequirePaymentAmountCustom = "The field is required.";
        isValid = false;
      } else if (
        this.topup.payment_amount &&
        !this.isPositiveInteger(this.topup.payment_amount)
      ) {
        this.fieldRequired.isPaymentAmountRequire = true;
        this.textRequirePaymentAmountCustom = "You must enter a positive.";
        isValid = false;
      } else if (
        this.topup.payment_amount &&
        this.topup.payment_amount.length > 7
      ) {
        this.fieldRequired.isPaymentAmountRequire = true;
        this.textRequirePaymentAmountCustom =
          "You can only enter up to 7 numeric characters.";
        isValid = false;
      }

      if (!this.topup.noExpiry) {
        if (
          this.topup.expiry_number == "" ||
          this.topup.expiry_number == null
        ) {
          this.fieldRequired.isExpiryRequire = true;
          this.textRequireExpiryCustom = "The field is required.";
          isValid = false;
        } else if (
          this.topup.expiry_number &&
          !this.isPositiveInteger(this.topup.expiry_number)
        ) {
          this.fieldRequired.isExpiryRequire = true;
          this.textRequireExpiryCustom = "You must enter a positive.";
          isValid = false;
        } else if (
          this.topup.expiry_unit == null ||
          this.topup.expiry_unit == "" ||
          this.topup.expiry_unit == 0
        ) {
          this.fieldRequired.isExpiryRequire = true;
          this.textRequireExpiryCustom = "You must choose a time unit.";
          isValid = false;
        }
      }

      if (this.topup.limit_type == 2) {
        if (
          this.topup.limit_number == "" ||
          this.topup.limit_number == null
        ) {
          this.fieldRequired.isLimitRequire = true;
          this.textRequireLimitCustom = "The field is required.";
          isValid = false;
        } else if (
          this.topup.limit_number &&
          !this.isPositiveInteger(this.topup.limit_number)
        ) {
          this.fieldRequired.isLimitRequire = true;
          this.textRequireLimitCustom = "You must enter a positive.";
          isValid = false;
        } else if (
          this.topup.limit_unit == null ||
          this.topup.limit_unit == "" ||
          this.topup.limit_unit == 0
        ) {
          this.fieldRequired.isLimitRequire = true;
          this.textRequireLimitCustom = "You must choose a time unit.";
          isValid = false;
        }
      }

      return isValid;
    },
    isPositiveInteger(str) {
      return parseFloat(str) > 0;
    },
    clearData() {
      this.resetRequired();
      this.clearImage();
      this.topup = {
        id: 0,
        name: "",
        image: null,
        payment_amount: 0,
        expiry_number: 0,
        expiry_unit: 1,
        promotion_type: 1,
        limit_type: 1,
        limit_number: 0,
        limit_unit: 1,
        noExpiry: false,
        type: 2, // Promotional Top Up
      };
      this.snackbar = null;
      this.message = "";
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
    closeSnackbar() {
      this.snackbar = null;
    },
  },
  watch: {
    keyReload() {
      this.clearData();

      if (this.isEdit) {
        this.topupDetail();
      }
    },
  },
};
</script>

<style scoped>
.r-no-expiry .form-check {
  padding-left: 0 !important;
}

#preview img {
  max-width: 150px;
  max-height: 60px;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 50%;
    margin: 1.75rem auto;
  }
}
</style>
