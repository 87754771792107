<template>
  <div>
    <button
      style="display: none"
      type="button"
      id="show-modal-add-regular-topup"
      data-bs-toggle="modal"
      data-bs-target="#ModalAddRegularTopUp"
    ></button>
    <div
      class="modal fade"
      id="ModalAddRegularTopUp"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ isEdit ? "EDIT REGULAR TOP UP" : "ADD NEW REGULAR TOP UP" }}
            </h5>
          </div>
          <div class="modal-body">
            <div class="row d-flex bd-highlight mt-3">
              <div class="col-lg-4 col-4 align-self-center">
                Topup Name<span class="text-danger ms-2">*</span>
              </div>
              <div
                class="col-lg-8 col-8 d-flex bd-highlight d-flex justify-content-end"
              >
                <material-input
                  v-model="topup.topup_name"
                  extraClass="ml-5"
                  placeholder="Enter topup name"
                />
              </div>
            </div>
            <div
              class="row d-flex bd-highlight"
              v-if="fieldRequired.isTopupNameRequire"
            >
              <div class="col-lg-4 col-4 align-self-center"></div>
              <div class="col-lg-8 col-8 d-flex bd-highlight">
                <span class="is-invalid">{{
                  textRequireTopupAmountCustom
                }}</span>
              </div>
            </div>
            <div class="row d-flex bd-highlight mt-3">
              <div class="col-lg-4 col-4 align-self-center">
                Payment Amount<span class="text-danger ms-2">*</span>
              </div>
              <div
                class="col-lg-8 col-8 d-flex bd-highlight d-flex justify-content-end"
              >
                <material-input
                  v-model="topup.payment_amount"
                  extraClass="ml-5"
                  placeholder="Enter payment amount"
                  type="number"
                  @input="amountChange()"
                />
              </div>
            </div>
            <div
              class="row d-flex bd-highlight"
              v-if="fieldRequired.isPaymentAmountRequire"
            >
              <div class="col-lg-4 col-4 align-self-center"></div>
              <div class="col-lg-8 col-8 d-flex bd-highlight">
                <span class="is-invalid">{{
                  textRequirePaymentAmountCustom
                }}</span>
              </div>
            </div>
            <div class="row d-flex bd-highlight mt-3">
              <div class="col-lg-4 col-4 align-self-center">Bonus Amount</div>
              <div
                class="col-lg-8 col-8 d-flex align-items-center"
                style="color: #50565d !important; font-size: 14px"
              >
                <material-input
                  v-model="topup.bonus_amount"
                  extraClass="ml-5"
                  placeholder="Enter bonus amount"
                  type="number"
                  @input="amountChange()"
                />
              </div>
            </div>
            <div class="row d-flex bd-highlight mt-3">
              <div class="col-lg-4 col-4 align-self-center">
                Total to Add<span class="text-danger ms-2">*</span>
              </div>
              <div
                class="col-lg-8 col-8 d-flex align-items-center"
                style="color: #50565d !important; font-size: 14px"
              >
                {{ topup.topup_amount }}
              </div>
            </div>
            <div class="row d-flex bd-highlight mt-3">
              <div class="col-lg-4 col-4 align-self-center">
                Paid Amount Expiry<span class="text-danger ms-2">*</span>
              </div>
              <div
                class="col-lg-3 col-3 d-flex bd-highlight d-flex justify-content-end pe-5"
              >
                <material-input
                  v-model="topup.paid_amount_expiry_number"
                  extraClass="ml-5"
                  placeholder="Number"
                  type="number"
                />
              </div>
              <div
                class="col-lg-3 col-3 d-flex bd-highlight d-flex justify-content-end"
              >
                <Multiselect
                  placeholder="Select Time"
                  :canClear="false"
                  :options="timeUnits"
                  v-model="topup.paid_amount_expiry_unit"
                />
              </div>
              <div
                class="col-lg-2 col-2 d-flex bd-highlight d-flex justify-content-center align-items-center r-no-expiry"
              >
                <material-checkbox
                  v-model="topup.noExpiryPaidAmount"
                  :checked="topup.noExpiryPaidAmount"
                  name="no_expiry_paid_amount"
                  id="no_expiry_paid_amount"
                  class="checkbox-custom"
                >
                  <template v-slot:label>No Expiry</template></material-checkbox
                >
              </div>
            </div>
            <div
              class="row d-flex bd-highlight"
              v-if="fieldRequired.isExpiryRequire"
            >
              <div class="col-lg-4 col-4 align-self-center"></div>
              <div class="col-lg-8 col-8 d-flex bd-highlight">
                <span class="is-invalid">{{ textRequireExpiryCustom }}</span>
              </div>
            </div>
            <div class="row d-flex bd-highlight mt-3">
              <div class="col-lg-4 col-4 align-self-center">
                Bonus Expiry<span class="text-danger ms-2">*</span>
              </div>
              <div
                class="col-lg-3 col-3 d-flex bd-highlight d-flex justify-content-end pe-5"
              >
                <material-input
                  v-model="topup.bonus_expiry_number"
                  extraClass="ml-5"
                  placeholder="Number"
                  type="number"
                />
              </div>
              <div
                class="col-lg-3 col-3 d-flex bd-highlight d-flex justify-content-end"
              >
                <Multiselect
                  placeholder="Select Time"
                  :canClear="false"
                  :options="timeUnits"
                  v-model="topup.bonus_expiry_unit"
                />
              </div>
              <div
                class="col-lg-2 col-2 d-flex bd-highlight d-flex justify-content-center align-items-center r-no-expiry"
              >
                <material-checkbox
                  v-model="topup.noExpiryBonus"
                  :checked="topup.noExpiryBonus"
                  name="no_expiry_bonus"
                  id="no_expiry_bonus"
                  class="checkbox-custom"
                >
                  <template v-slot:label>No Expiry</template></material-checkbox
                >
              </div>
            </div>
            <div
              class="row d-flex bd-highlight"
              v-if="fieldRequired.isExpiryBonusRequire"
            >
              <div class="col-lg-4 col-4 align-self-center"></div>
              <div class="col-lg-8 col-8 d-flex bd-highlight">
                <span class="is-invalid">{{
                  textRequireExpiryBonusCustom
                }}</span>
              </div>
            </div>
          </div>
          <!-- Modal content-->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-success btn-background btn-sm"
              @click="saveTopup"
            >
              Save
            </button>
            <button
              type="button"
              class="btn btn-primary btn-close-modal-add-regular-topup btn-sm"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
        <div class="position-fixed top-1 end-1 z-index-2 message-success">
          <material-snackbar
            v-if="snackbar === 'danger' || snackbar === 'success'"
            title
            date
            :description="message"
            :icon="{ component: 'campaign', color: 'white' }"
            :color="snackbar"
            :close-handler="closeSnackbar"
          />
        </div>
        <material-loading :active="loaderActive" />
      </div>
    </div>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import Multiselect from "@vueform/multiselect";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import TopUpService from "../../../../services/settingTopUp.service";
import $ from "jquery";

export default {
  name: "ModalAddRegularTopUp",
  components: {
    MaterialSnackbar,
    MaterialInput,
    Multiselect,
    MaterialLoading,
    MaterialCheckbox,
  },
  props: {
    keyReload: {
      type: String,
      required: true,
    },
    isEdit: {
      type: Number,
      required: 0,
    },
  },
  data() {
    return {
      loaderActive: false,
      message: "",
      snackbar: null,
      topup: {
        id: 0,
        topup_name: "",
        topup_amount: 0,
        payment_amount: 0,
        bonus_amount: 0,
        paid_amount_expiry_number: "",
        paid_amount_expiry_unit: 1,
        bonus_expiry_number: "",
        bonus_expiry_unit: 1,
        noExpiryPaidAmount: false,
        noExpiryBonus: false,
      },
      timeUnits: [
        {
          value: 1,
          label: "days",
        },
        {
          value: 2,
          label: "months",
        },
        {
          value: 3,
          label: "years",
        },
      ],
      fieldRequired: {},
      textRequireTopupAmountCustom: "",
      textRequirePaymentAmountCustom: "",
      textRequireExpiryCustom: "",
      textRequireExpiryBonusCustom: "",
    };
  },
  methods: {
    topupDetail() {
      this.showLoader();
      TopUpService.detail(this.isEdit).then(
        (response) => {
          if (response.data.result.isSuccess) {
            const topup = response.data.data;
            this.isLoadDoneData = true;
            this.topup.id = topup.id ?? 0;
            this.topup.topup_name = topup.topup_name ?? "";
            this.topup.topup_amount = topup.topup_amount ?? 0;
            this.topup.payment_amount = topup.payment_amount ?? 0;
            this.topup.bonus_amount = topup.bonus_amount ?? 0;
            this.topup.paid_amount_expiry_number =
              topup.paid_amount_expiry_number ?? "";
            this.topup.paid_amount_expiry_unit =
              topup.paid_amount_expiry_unit ?? 1;
            this.topup.bonus_expiry_number = topup.bonus_expiry_number ?? "";
            this.topup.bonus_expiry_unit = topup.bonus_expiry_unit ?? 1;
            this.topup.noExpiryPaidAmount = topup.paid_amount_expiry_number
              ? false
              : true;
            this.topup.noExpiryBonus = topup.bonus_expiry_number ? false : true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    saveTopup() {
      if (this.processValidate()) {
        if (!this.topup.id) {
          TopUpService.store(this.topup).then(
            (response) => {
              if (response.data.result.isSuccess) {
                $(".btn-close-modal-add-regular-topup").click();
                this.$emit("reload-data", "Add Regular Top Up Success");
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        } else {
          TopUpService.update(this.topup).then(
            (response) => {
              if (response.data.result.isSuccess) {
                $(".btn-close-modal-add-regular-topup").click();
                this.$emit("reload-data", "Update Regular Top Up Success");
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        }
      }
    },
    amountChange() {
      var payment_amount = this.topup.payment_amount
        ? parseFloat(this.topup.payment_amount)
        : 0;
      var bonus_amount = this.topup.bonus_amount
        ? parseFloat(this.topup.bonus_amount)
        : 0;
      this.topup.topup_amount = parseFloat(
        payment_amount + bonus_amount
      ).toFixed(2);
    },
    resetRequired() {
      this.fieldRequired.isTopupNameRequire = false;
      this.fieldRequired.isPaymentAmountRequire = false;
      this.fieldRequired.isExpiryRequire = false;
      this.fieldRequired.isExpiryBonusRequire = false;
    },
    processValidate() {
      this.resetRequired();
      var isValid = true;
      if (this.topup.topup_name == "" || this.topup.topup_name == null) {
        this.fieldRequired.isTopupNameRequire = true;
        this.textRequireTopupAmountCustom = "The field is required.";
        isValid = false;
      }
      if (
        this.topup.payment_amount == "" ||
        this.topup.payment_amount == null
      ) {
        this.fieldRequired.isPaymentAmountRequire = true;
        this.textRequirePaymentAmountCustom = "The field is required.";
        isValid = false;
      } else if (
        this.topup.payment_amount &&
        !this.isPositiveInteger(this.topup.payment_amount)
      ) {
        this.fieldRequired.isPaymentAmountRequire = true;
        this.textRequirePaymentAmountCustom = "You must enter a positive.";
        isValid = false;
      } else if (
        this.topup.payment_amount &&
        this.topup.payment_amount.length > 7
      ) {
        this.fieldRequired.isPaymentAmountRequire = true;
        this.textRequirePaymentAmountCustom =
          "You can only enter up to 7 numeric characters.";
        isValid = false;
      } else if (
        this.topup.topup_amount &&
        this.topup.payment_amount &&
        parseInt(this.topup.topup_amount) < parseInt(this.topup.payment_amount)
      ) {
        this.fieldRequired.isPaymentAmountRequire = true;
        this.textRequirePaymentAmountCustom =
          "You must enter top up amount greater than or equal to payment amount.";
        isValid = false;
      }

      if (!this.topup.noExpiryPaidAmount) {
        if (
          this.topup.paid_amount_expiry_number == "" ||
          this.topup.paid_amount_expiry_number == null
        ) {
          this.fieldRequired.isExpiryRequire = true;
          this.textRequireExpiryCustom = "The field is required.";
          isValid = false;
        } else if (
          this.topup.paid_amount_expiry_number &&
          !this.isPositiveInteger(this.topup.paid_amount_expiry_number)
        ) {
          this.fieldRequired.isExpiryRequire = true;
          this.textRequireExpiryCustom = "You must enter a positive.";
          isValid = false;
        } else if (
          this.topup.paid_amount_expiry_unit == null ||
          this.topup.paid_amount_expiry_unit == "" ||
          this.topup.paid_amount_expiry_unit == 0
        ) {
          this.fieldRequired.isExpiryRequire = true;
          this.textRequireExpiryCustom = "You must choose a time unit.";
          isValid = false;
        }
      }
      if (!this.topup.noExpiryBonus) {
        if (
          this.topup.bonus_expiry_number == "" ||
          this.topup.bonus_expiry_number == null
        ) {
          this.fieldRequired.isExpiryBonusRequire = true;
          this.textRequireExpiryBonusCustom = "The field is required.";
          isValid = false;
        } else if (
          this.topup.bonus_expiry_number &&
          !this.isPositiveInteger(this.topup.bonus_expiry_number)
        ) {
          this.fieldRequired.isExpiryBonusRequire = true;
          this.textRequireExpiryBonusCustom = "You must enter a positive.";
          isValid = false;
        } else if (
          this.topup.bonus_expiry_unit == null ||
          this.topup.bonus_expiry_unit == "" ||
          this.topup.bonus_expiry_unit == 0
        ) {
          this.fieldRequired.isExpiryBonusRequire = true;
          this.textRequireExpiryBonusCustom = "You must choose a time unit.";
          isValid = false;
        }
      }
      return isValid;
    },
    isPositiveInteger(str) {
      return parseFloat(str) > 0;
    },
    clearData() {
      this.resetRequired();
      this.topup = {
        id: 0,
        topup_name: "",
        topup_amount: 0,
        payment_amount: 0,
        bonus_amount: 0,
        paid_amount_expiry_number: "",
        paid_amount_expiry_unit: 1,
        bonus_expiry_number: "",
        bonus_expiry_unit: 1,
        noExpiryPaidAmount: false,
        noExpiryBonus: false,
      };
      this.snackbar = null;
      this.message = "";
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
    closeSnackbar() {
      this.snackbar = null;
    },
  },
  watch: {
    keyReload() {
      this.clearData();

      if (this.isEdit) {
        this.topupDetail();
      }
    },
  },
};
</script>

<style scoped>
.r-no-expiry .form-check {
  padding-left: 0 !important;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 50%;
    margin: 1.75rem auto;
  }
}
</style>
