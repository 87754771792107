import api from './api';

class SettingTopUpService {
  getList(dataForm) {
    return api.get('/merchant/top-up', {params: dataForm});
  }

  detail(topUpId, type = 1) {
    return api.get('/merchant/top-up/' + topUpId + '?type=' + type);
  }

  store(dataForm) {
    return api.post('/merchant/top-up', dataForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(dataForm) {
    return api.post('/merchant/top-up/' + dataForm.id + '/update', dataForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(topUpId) {
    return api.delete('merchant/top-up/' + topUpId);
  }

  saveTermsConditions(dataForm) {
    return api.post('merchant/top-up/add-terms-conditions', dataForm);
  }
}

export default new SettingTopUpService();
